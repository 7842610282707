import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PickPackProvider extends HttpRequest {
  getPickPackLogs (query) {
    this.setHeader(getAuthToken())
    return this.get('pick-pack', query)
  }
}

export default PickPackProvider
